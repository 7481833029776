<template>
	<div class="empty">
		<img src="../../assets/empty.png" alt="">
		<p class="text_theme">{{ text }}</p>
	</div>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				default: '此部分为空~'
			}
		}
	}
</script>

<style scoped>
	.empty{ padding: 50px 0; }
	.empty img{ display: block;width: 120px;margin: 0 auto; }
	.empty p{ margin-top: 10px;font-size: 16px;text-align: center;line-height: 24px; }
</style>
