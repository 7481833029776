<template>
	<div>
		<!-- 研发背景 -->
		<company-profile></company-profile>
		<!-- 研发背景 end -->
		
		<!-- 合作单位 -->
		<cooperation-unit></cooperation-unit>
		<!-- 合作单位 end -->
	</div>
</template>

<script>
	import CompanyProfile from './components/company_profile.vue';
	import CooperationUnit from './components/cooperation_unit.vue';
	
	export default {
		components: {
			CompanyProfile,
			CooperationUnit
		}
	}
</script>

<style scoped>
	
</style>
