<template>
	<div class="cooperation_unit">
		<h3 class="title">合作单位</h3>
		<div class="swiper_box">
			<div class="swiper-container" style="height: 100%;">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div v-for="(v, i) in list_01" :key="i" class="item">
							<div class="item_logo">
								<img :src="v.logo" alt="">
							</div>
							<p class="item_desc text_one" style="margin-top: 5px;">{{ v.title }}</p>
							<p class="item_desc text_one">{{ v.subTitle || '' }}</p>
						</div>
					</div>
					<div class="swiper-slide">
						<div v-for="(v, i) in list_02" :key="i" class="item">
							<div class="item_logo">
								<img :src="v.logo" alt="">
							</div>
							<p class="item_desc text_one" style="margin-top: 5px;">{{ v.title }}</p>
							<p class="item_desc text_one">{{ v.subTitle || '' }}</p>
						</div>
					</div>
					<div class="swiper-slide">
						<div v-for="(v, i) in list_03" :key="i" class="item">
							<div class="item_logo">
								<img :src="v.logo" alt="">
							</div>
							<p class="item_desc text_one" style="margin-top: 5px;">{{ v.title }}</p>
							<p class="item_desc text_one">{{ v.subTitle || '' }}</p>
						</div>
					</div>
				</div>
			</div>
			<ul class="Indicator">
				<li :class="{'bg_theme': indicator_index == 0}" style="margin-right: 5px;"></li>
				<li :class="{'bg_theme': indicator_index == 1}" style="margin-right: 5px;"></li>
				<li :class="{'bg_theme': indicator_index == 2}"></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		mounted() {
			setTimeout(() => {
				this.initSwiper();
			}, 150);
		},
		data() {
			return {
				list_01: [{
					logo: require('../../../../assets/cooperation_unit_12.png'),
					title: '中国科学技术大学',
					subTitle: '先进技术研究院'
				}, {
					logo: require('../../../../assets/cooperation_unit_13.png'),
					title: '中国科学院脑功能与',
					subTitle: '脑疾病重点实验室'
				}, {
					logo: require('../../../../assets/cooperation_unit_09.png'),
					title: '中国科学技术大学',
					subTitle: '视觉研究实验室'
				}, {
					logo: require('../../../../assets/cooperation_unit_01.png'),
					title: '欧普康视'
				}, {
					logo: require('../../../../assets/cooperation_unit_02.png'),
					title: '南京医科大学',
					subTitle: '视觉与认知研究中心'
				}, {
					logo: require('../../../../assets/cooperation_unit_03.png'),
					title: '天津医科大学',
					subTitle: '眼科医院'
				}, {
					logo: require('../../../../assets/cooperation_unit_14.jpg'),
					title: '爱尔眼科集团',
					subTitle: '医院（部分）'
				}, {
					logo: require('../../../../assets/cooperation_unit_15.jpg'),
					title: '安徽省立医院'
				}],
				list_02: [{
					logo: require('../../../../assets/cooperation_unit_16.jpg'),
					title: '安徽医科大学',
					subTitle: '第一附属医院'
				}, {
					logo: require('../../../../assets/cooperation_unit_17.jpg'),
					title: '北京市儿童医院'
				}, {
					logo: require('../../../../assets/cooperation_unit_18.jpg'),
					title: '华夏集团眼科',
					subTitle: '医院（部分）'
				}, {
					logo: require('../../../../assets/cooperation_unit_19.jpg'),
					title: '山东省立医院'
				}, {
					logo: require('../../../../assets/cooperation_unit_20.jpg'),
					title: '南京市妇幼保健院'
				}, {
					logo: require('../../../../assets/cooperation_unit_04.png'),
					title: '安徽讯飞产业投资',
					subTitle: '有限责任公司'
				}, {
					logo: require('../../../../assets/cooperation_unit_10.png'),
					title: '加拿大麦吉尔大学'
				}, {
					logo: require('../../../../assets/cooperation_unit_06.png'),
					title: '美国加州大学',
					subTitle: '洛杉矶分校'
				}],
				list_03: [{
					logo: require('../../../../assets/cooperation_unit_07.png'),
					title: '美国佛罗里达大学'
				}, {
					logo: require('../../../../assets/cooperation_unit_08.png'),
					title: '美国密西西比州大学'
				}, {
					logo: require('../../../../assets/cooperation_unit_05.png'),
					title: '美国南加州大学'
				}],
				indicator_index: 0
			}
		},
		methods: {
			/***************** @Function **********************/
			// 初始化swiper
			initSwiper() {
				const that = this;
				that.swiper_instance = new that.Swiper('.cooperation_unit .swiper-container', {
					loop: false,
					allowTouchMove: true,
					on: {
						slideChangeTransitionEnd: function(){
							let index = this.activeIndex;
							that.indicator_index = index;
							// console.log(index);
						}
					}
				});
			}
		}
	}
</script>

<style scoped>
	.cooperation_unit{ padding: 15px; }
	.cooperation_unit .title{ position: relative;padding: 0 0 0 10px;line-height: 30px; }
	.cooperation_unit .title:before{ content: "";display: block;position: absolute;width: 4px;height: 12px;top: 9px;left: 0;background: #2b53c6; }
	.cooperation_unit .swiper_box{ position: relative;height: 240px;margin-top: 5px; }
	.cooperation_unit .item{ display: block;float: left;width: 25%;margin-bottom: 15px;padding: 0 5px; }
	.cooperation_unit .item_logo{ display: block;width: 60px;height: 60px;margin: 0 auto; }
	.cooperation_unit .item_logo img{ display: block;width: 100%;height: 100%; }
	.cooperation_unit .item_desc{ height: 20px;color: #333;text-align: center;line-height: 20px; }
	.cooperation_unit .Indicator{ position: absolute;z-index: 9;width: 100%;height: 8px;bottom: 0;left: 0;text-align: center; }
	.cooperation_unit .Indicator li{ display: inline-block;width: 8px;height: 8px;border-radius: 4px;background: #e8e8e8;vertical-align: top; }
</style>
