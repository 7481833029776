import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		loading: false,
		safeInfo: {}
	},
	mutations: {
		setLoading(state, loading) {
			state.loading = loading;
		},
		setSafeInfo(state, safeInfo) {
			state.safeInfo = safeInfo;
		}
	}
})

export default store
