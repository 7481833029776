<template>
	<div class="index_banner">
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide">
					<img class="img_box" src="../../../assets/banner.png" alt="">
				</div>
				<div v-for="v in data" :key="v.id" class="swiper-slide">
					<img class="img_box" :src="v.pictureUrl" alt="">
				</div>
			</div>
		</div>
		<ul class="Indicator">
			<li :class="{'bg_theme': indicator_index == 0}" style="margin-right: 5px;"></li>
			<li v-for="(v, i) in data" :key="v.id" :class="{'bg_theme': indicator_index == i + 1}" style="margin-right: 5px;"></li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Array,
				default() { return []; }
			}
		},
		mounted() {
			this.initSwiper();
		},
		data() {
			return {
				indicator_index: 0
			}
		},
		methods: {
			/***************** @Function **********************/
			// 初始化swiper
			initSwiper() {
				const that = this;
				that.swiper_instance = new that.Swiper('.index_banner .swiper-container', {
					loop: true,
					allowTouchMove: true,
					autoplay: {
						delay: 5000,
						disableOnInteraction: false
					},
					on: {
						click: function () {
							let realIndex = this.realIndex;
							(realIndex > 0 && that.data[realIndex - 1].link)
							&& (window.location.href = that.data[realIndex - 1].link)
						},
						slideChangeTransitionEnd: function(){
							let index = this.activeIndex - 1;
							index < 0 && (index = that.data.length);
							index >= that.data.length + 1 && (index = 0);
							that.indicator_index = index;
						}
					}
				});
			}
		},
		watch: {
			data() {
				this.swiper_instance && this.swiper_instance.destroy();
				this.$nextTick(() => {
					this.initSwiper();
				});
			}
		}
	}
</script>

<style scoped>
	.index_banner{ position: relative;height: 150px; }
	.index_banner .swiper-container{ height: 100%; }
	.index_banner .img_box{ display: block;position: absolute;width: 100%;top: 50%;left: 50%;transform: translate(-50%, -50%); }
	.index_banner .Indicator{ position: absolute;z-index: 9;width: 100%;height: 8px;bottom: 5px;left: 0;text-align: center; }
	.index_banner .Indicator li{ display: inline-block;width: 8px;height: 8px;border-radius: 4px;background: #e8e8e8;vertical-align: top; }
</style>
