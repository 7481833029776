<template>
	<div>
		<template v-if="network_success">
			<!-- 顶部导航 -->
			<navigation></navigation>
			<!-- 顶部导航 end -->
			
			<div style="height: 50px;"></div>
			
			<!-- banner -->
			<banner :data="banners"></banner>
			<!-- banner end -->
			
			<!-- 切换导航 -->
			<tab-bar></tab-bar>
			<!-- 切换导航 end -->
			
			<router-view></router-view>
			
			<!-- 底部 -->
			<Footer></Footer>
			<!-- 底部 end -->
		</template>
		
		<!-- 网络错误 -->
		<network-err v-if="!network_success" @onRefresh="handleNetworkRefresh"></network-err>
		<!-- 网络错误 end -->
	</div>
</template>

<script>
	import Navigation from './components/navigation.vue';
	import Banner from './components/banner.vue';
	import TabBar from './components/tabbar.vue';
	import Footer from './components/footer.vue';
	import Api from '../../api/index.js';
	import { mapMutations } from 'vuex';
	
	export default {
		mounted() {
			this.getBanner();
		},
		data() {
			return {
				network_success: true,
				banners: []
			}
		},
		methods: {
			/************************** @Events ***************************/
			// 网络刷新
			handleNetworkRefresh() {
				this.getBanner();
			},
			
			/************************** @Function ***************************/
			// 获取banner
			getBanner() {
				const that = this;
				that.setLoading(true);
				let requestData = {
					limit: null,
					pageNum: null,
					type: 1
				};
				Api.findHomeBanner(requestData).then((res) => {
					if (res.data.success) {
						that.banners = res.data.data || [];
						that.setLoading(false);
					} else {
						that.setLoading(false);
						that.network_success = false;
					}
				}, () => {
					that.setLoading(false);
					that.network_success = false;
				});
			},
			
			...mapMutations({
				'setLoading': 'setLoading'
			})
		},
		components: {
			Navigation,
			Banner,
			TabBar,
			Footer
		}
	}
</script>

<style scoped>
</style>
