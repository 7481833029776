import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import Index from '../pages/index/index.vue';
import IndexHome from '../pages/index/home/home.vue';
import IndexDownload from '../pages/index/download.vue';

/******************************* 导出数据 ********************************/
export default new Router({
	mode: "history",
	routes: [{
		path: "/index",
		component: Index,
		children: [{
			path: "/index/home",
			component: IndexHome
		}, {
			path: "/index/download",
			component: IndexDownload
		}, {
			path: '/index',
			redirect: '/index/home'
		}, {
			path: '/index/*',
			redirect: '/index/home'
		}]
	}, {
		path: '*',
		redirect: '/index'
	}]
})
