<template>
	<div class="network_err">
		<img src="../../assets/network.png" alt="">
		<p class="text_theme">网络出现错误~</p>
		<div><button @click="handleButtonClick">请刷新重试</button></div>
	</div>
</template>

<script>
	export default {
		methods: {
			handleButtonClick() {
				this.$emit('onRefresh');
			}
		}
	}
</script>

<style scoped>
	.network_err{ padding: 50px 0; }
	.network_err img{ display: block;width: 160px;margin: 0 auto; }
	.network_err p{ margin-top: 10px;font-size: 16px;text-align: center;line-height: 24px; }
	.network_err div{ margin-top: 10px;text-align: center; }
	.network_err button{ height: 34px;padding: 0 15px;border-radius: 17px;font-size: 16px; }
</style>
