<template>
	<div class="tab_bar">
		<ul>
			<li class="text_one" :class="{'active': active_index == 0,'bg_theme': active_index == 0}" data-url="/index/home" @click="handleLinkClick">首页</li>
			<li class="text_one" :class="{'active': active_index == 1,'bg_theme': active_index == 1}" data-url="/index/download" @click="handleLinkClick">产品下载</li>
		</ul>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.updateIndexByRoute();
		},
		data() {
			return {
				active_index: -1
			}
		},
		methods: {
			/********************** @Events ****************************/
			// 点击选项卡
			handleLinkClick(e) {
				let path = e.target.dataset.url;
				let current_path = this.$route.path;
				path != current_path && this.$router.push({ path });
			},
			
			/********************** @Function ****************************/
			// 根据路由改变高亮索引
			updateIndexByRoute() {
				let path = this.$route.path;
				switch(path) {
					case '/index/home':
						this.active_index = 0;
						break;
					case '/index/download':
						this.active_index = 1;
						break;
					default:
						this.active_index = -1;
						break;
				}
			}
		},
		watch: {
			$route() {
				this.updateIndexByRoute();
			}
		}
	}
</script>

<style scoped>
	.tab_bar{ padding: 0 5px; }
	.tab_bar ul{ display: flex;border: 1px solid #2b53c6; }
	.tab_bar li{ display: block;flex: 1;border-right: 1px solid #2b53c6;text-align: center;line-height: 60px; }
	.tab_bar li:last-child{ border-right: none; }
	.tab_bar .active{ color: #fff; }
</style>
